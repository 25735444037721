<template>
  <div class="vx-row">
    <div class="vx-col md:w-1/1 w-full">
      <vx-card title="Edit Company">

        <div class="vx-row mb-6">
          <vs-button
            class="ml-4 mt-2"
            color="danger"
            icon-pack="feather"
            icon="icon-arrow-left"
            @click="handleBack()"
          >Back
          </vs-button>
        </div>

        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Code</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input name="code" class="w-full" v-if="id!=0" disabled v-model="responseData.code" />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Name</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              v-validate="'required'"
              name="name"
              class="w-full"
               v-if="id!=0" disabled
              v-model="responseData.name"
            />
            <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first('name') }}</span>
          </div>
        </div>
        <address-component
          :data="address"
          @data="setDataAddress"
        ></address-component>
        <!-- <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Address</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              v-validate="'required'"
              name="address"
              class="w-full"
              v-model="responseData.address"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('address')"
            >{{ errors.first('address') }}</span>
          </div>
        </div> -->
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Map Address</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              name="map_address"
              placeholder="Map Address"
              class="w-full"
              v-model="responseData.map_address"
            />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Latitude</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input name="latitude" class="w-full" v-model="responseData.latitude" />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Longitude</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input name="longitude" class="w-full" v-model="responseData.longitude" />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Phone</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              name="phone"
              v-validate="'required|numeric'"
              class="w-full"
              v-model="responseData.phone"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('phone')"
            >{{ errors.first('phone') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Fax</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input name="fax" placeholder="fax" class="w-full" v-model="responseData.fax" />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Email</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              name="email"
              v-validate="'email'"
              placeholder="email"
              class="w-full"
              v-model="responseData.email"
            />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Contact Name</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              name="contact_name"
              placeholder="Contact Name"
              class="w-full"
              v-model="responseData.contact_name"
            />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Contact Mobile</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              name="contact_mobile"
              placeholder="Contact Mobile"
              class="w-full"
              v-model="responseData.contact_mobile"
            />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Base Currency</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <v-select
              name="Base Currency"
              v-validate="'required'"
              placeholder="Select base currency"
              v-model="responseData.base_currency"
              @input="setSelectedBaseCurrency"
              label="name"
              :options="optionBaseCurrency"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('Work Group')"
            >{{ errors.first('Work Group') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>PKP</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-switch
              color="success"
              icon-pack="feather"
              vs-icon-on="icon-check-circle"
              vs-icon-off="icon-slash"
              v-model="responseData.pkp"
            >
              <span slot="on">YES</span>
              <span slot="off">NO</span>
            </vs-switch>
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>NPWP</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              name="npwp"
              placeholder="NPWP"
              class="w-full"
              v-model="responseData.npwp"
            />
          </div>
        </div>

        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>NPWP 16 Digit</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              name="npwp_new"
              placeholder="NPWP 16 Digit"
              class="w-full"
              v-validate="'min:16|max:16'"
              v-model="responseData.npwp_new"
              data-vv-as="NPWP 16 Digit"
            />
            <span class="text-danger text-sm" v-show="errors.has('npwp_new')">{{ errors.first('npwp_new') }}</span>
          </div>
        </div>

        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>TDP</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              name="tdp"
              placeholder="tdp"
              class="w-full"
              v-model="responseData.tdp"
            />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>SIUP</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              name="siup"
              placeholder="siup"
              class="w-full"
              v-model="responseData.siup"
            />
          </div>
        </div>

        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>NITKU</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              name="nitku"
              placeholder="nitku"
              class="w-full"
              v-validate="'min:22|max:22'"
              v-model="responseData.nitku"
            />
            <span class="text-danger text-sm" v-show="errors.has('nitku')">{{ errors.first('nitku') }}</span>
          </div>
        </div>

        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Logo</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <input
              name="file"
              class="w-full inputx"
              v-if="uploadReady"
              type="file"
              ref="file"
              accept=".jpg, .jpeg, .png"
            />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span></span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <img style="max-height: 170px; max-width: auto;" :src="responseData.logo" />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-4/5 w-full ml-auto">
            <vs-button v-on:click="handleSubmit" class="mr-3 mb-2">Submit</vs-button>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import AddressComponent from "@/components/master/AddressCompany";
import vSelect from "vue-select";
export default {
  components: {
    AddressComponent,
    vSelect,
  },
  created() {
    this.getCompanyData();
    this.getMasterCurrency()
  },
  data() {
    return this.initialState();
  },
  methods: {
    getMasterCurrency() {
      this.$vs.loading();
      this.$http
        .get("api/v1/master/currency")
        .then(resp => {
          this.$vs.loading.close();
          if (resp.status == "success") {
            let data = []
            resp.data.records.forEach(function(element) {
              data.push({
                "id": element.ID,
                "name": element.Code
              })
            });
            this.optionBaseCurrency = data
            console.log(this.optionBaseCurrency)
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        });
    },
    getCompanyData() {
      this.$vs.loading();
      this.$http
        .get("api/v1/setting/company/" + this.$route.params.id)
        .then(resp => {
          this.$vs.loading.close();
          if (resp.status == "success") {
            this.responseData = resp.data;
            this.address.address = resp.data.address;
            this.address.postalCode = resp.data.postal_code;
            this.address.countryName = resp.data.country;
            this.address.provinceName = resp.data.province;
            this.address.cityName = resp.data.city;
            this.address.districtName = resp.data.district;
            this.address.subdistrictName = resp.data.sub_district;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        });
    },
    initialState() {
      return {
        statusImage: "old",
        uploadReady: true,
        optionBaseCurrency: [],
        address: {
          address: "",
          countryName: "",
          provinceName: "",
          cityName: "",
          districtName: "",
          subdistrictName: "",
          postalCode: "",
        },
        responseData: {},
      };
    },
    setDataAddress(val) {
      this.address = val;
    },
    handleSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          var file = this.$refs.file.files[0];
          if (file != null) {
            var validImageTypes = ["image/gif", "image/jpeg", "image/png"];
            if (!validImageTypes.includes(file.type)) {
              this.$vs.notify({
                color: "danger",
                title: "Form Validation",
                text: "File must be image",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            } else {
              var dateNow = new Date();
              var now =
                dateNow.getFullYear() +
                "" +
                (dateNow.getMonth() + 1) +
                "" +
                dateNow.getDate();
              now +=
                +"" +
                dateNow.getHours() +
                "" +
                dateNow.getMinutes() +
                "" +
                dateNow.getSeconds();

              this.formData = new FormData();
              this.formData.append("file", file);
              this.formData.append("name", (now + "_" + file.name));
              this.$vs.loading();
              this.$http
                .post("api/v1/setting/company/logo", this.formData, {
                  headers: {
                    "Content-Type": "multipart/form-data"
                  }
                })
                .then(resp => {
                  this.$vs.loading.close();
                  if (resp.status == "success") {
                    this.statusImage = 'new';
                    this.options = resp.data;
                    this.responseData.logo = now + "_" + file.name
                    this.putData();
                  } else {
                    this.$vs.notify({
                      color: "danger",
                      title: "Error",
                      text: resp.message,
                      position: "top-right",
                      iconPack: "feather",
                      icon: "icon-x-circle"
                    });
                  }
                });
            }
          } else {
            this.$vs.loading();
            this.putData();
          }
        }
      });
    },
    paramData() {
      return {
        code: this.responseData.code,
        name: this.responseData.name,
        shortName: this.responseData.short_name,

        country: this.address.countryName,
        province: this.address.provinceName,
        city: this.address.cityName,
        district: this.address.districtName,
        subDistrict: this.address.subdistrictName,
        address: this.address.address,
        postalCode: this.address.postalCode,

        mapAddress: this.responseData.map_address,
        latitude: this.responseData.latitude,
        longitude: this.responseData.longitude,
        phone: this.responseData.phone,
        fax: this.responseData.fax,
        email: this.responseData.email,
        contactName: this.responseData.contact_name,
        contactMobile: this.responseData.contact_mobile,
        pkp: this.responseData.pkp,
        npwp: this.responseData.npwp,
        npwp_new: this.responseData.npwp_new,
        tdp: this.responseData.tdp,
        siup: this.responseData.siup,
        logo: this.responseData.logo,
        statusImage: this.statusImage,
        baseCurrency: this.baseCurrency,
        nitku: this.responseData.nitku,
      };
    },
    putData() {
      this.$http
        .put(
          "/api/v1/setting/company-v2/" + this.$route.params.id,
          this.paramData()
        )
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Data Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
            this.handleBack();
            this.responseData.logo = resp.data.logo;
            this.$userLogin.company_logo = resp.data.logo;
            this.$userLogin.favicon = resp.data.logo;
            document.getElementById("logo_company").src = resp.data.logo;
            document.getElementById("favicon").href = resp.data.logo;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleBack() {
      this.$vs.loading();
      this.$router.go(-1);
      this.$vs.loading.close();
    },
    setSelectedBaseCurrency(value) {
      this.baseCurrency = value.name
    },
  },
};
</script>
